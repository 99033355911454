import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageWrapper from "../components/shared/PageWrapper"
import { StaticImage } from "gatsby-plugin-image"

const AboutPage = () => {
  const pClasses = "max-w-prose md:max-w-sm lg:max-w-xl mb-2"
  return (
    <Layout>
      <Seo title="Login" />
      <PageWrapper>
        <div className="flex flex-col justify-center gap-8 md:gap-16 md:flex-row">
          <div className="flex-grow-1">
            <h2 className="text-4xl mb-5 text-indigo-500">
              Julie Ludwig, soprano
            </h2>

            <p className={pClasses}>
              Julie has been teaching voice and theory since 2007. She holds a
              Bachelor of Music (Honors Music History) and a Master of Music
              (Voice Performance) from Western University. She furthered her
              studies at the Britten-Pears Young Artist Programme in England,
              the Centre for Opera Studies in Italy, the Banff Centre for the
              Arts, and is an alumna of Calgary Opera’s emerging artist program.
            </p>
            <p className={pClasses}>
              Performance highlights include appearances with the Windsor
              Symphony, the Bach Elgar Choir, the Hamilton Philharmonic’s What
              Next Festival, Opera 5, the Calgary Civic Symphony, and recitals
              in British Columbia, Manitoba, and Ontario.
            </p>
            <p className={pClasses}>
              Julie’s students have been accepted into undergraduate and
              graduate programs in music, have performed leading roles in operas
              and musicals, and have received awards at local and provincial
              music festivals. Additionally, her students have attained high
              grades in voice and theory exams with the Royal Conservatory of
              Music.
            </p>
            <p className={pClasses}>
              She teaches in the music department at Redeemer University College
              and is an active adjudicator and masterclass clinician.
            </p>
          </div>
          <StaticImage
            src="../images/julie-headshot-2.jpg"
            className="place-self-end self-start flex-grow-0w-60 md:w-48 lg:w-72 xl:w-96"
          />
        </div>
      </PageWrapper>
    </Layout>
  )
}

export default AboutPage
